
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import PasswordSecurity from '@/components/pages/admin/workspace/security/PasswordSecurity.vue'
import Authentication from '@/components/pages/admin/workspace/security/Authentication.vue'

export default defineComponent({
  name: 'WorkspaceSecurity',
  components: {
    PageContent,
    PasswordSecurity,
    Authentication,
    DetailsHero,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Admin', url: { name: 'base.admin' } },
      { label: 'Workspace security', url: '' },
    ]

    return {
      breadcrumbs,
    }
  },
})
