import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_authentication = _resolveComponent("authentication")!
  const _component_password_security = _resolveComponent("password-security")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    "bg-shade": "",
    size: "large",
    breadcrumbs: _ctx.breadcrumbs
  }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    before: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        icon: "security",
        title: "Workspace security",
        color: "blue",
        class: "mb-4"
      }),
      _createVNode(_component_authentication, { class: "mb-4" }),
      _createVNode(_component_password_security)
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}