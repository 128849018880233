
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { openResetPasswordModal, openWorkspacePasswordPolicyModal } = useAccountOpenModal()
    return {
      openResetPasswordModal,
      openWorkspacePasswordPolicyModal,
    }
  },
})
