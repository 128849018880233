
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmChip from '@/components/shared/TmChip.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import useAccountOpenModal from '@/compositions/account/useAccountOpenModal'

export default defineComponent({
  components: {
    TmButton,
    TmChip,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const {
      open2FADisableModal,
      open2FAModal,
      openWhiteListModal,
      openSessionExpirationModal,
      openUserLoginsModal,
    } = useAccountOpenModal()

    const securityState = ref({
      twoFactorEnabled: false,
      whitelistEnabled: false,
    })

    const handle2FADisable = () => {
      open2FADisableModal(() => {
        securityState.value.twoFactorEnabled = !securityState.value.twoFactorEnabled
      })
    }

    const handle2FA = () => {
      open2FAModal(() => {
        securityState.value.twoFactorEnabled = !securityState.value.twoFactorEnabled
      })
    }

    const handleWhiteList = () => {
      openWhiteListModal(() => {
        securityState.value.whitelistEnabled = !securityState.value.whitelistEnabled
      })
    }

    return {
      securityState,
      openSessionExpirationModal,
      openUserLoginsModal,
      handle2FADisable,
      handle2FA,
      handleWhiteList,
    }
  },
})
